import { Component, OnInit, ViewChild } from '@angular/core';
import { TestesService } from 'src/app/shared/api/testes/testes.service';
import { testeResource } from '../teste.resource';
import { ActionToolbar } from 'src/app/shared/components/toolbar/toolbar.component';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { EditComponentComponent } from 'src/app/shared/components/edit-component/edit-component.component';
import {
    IDialogData,
    ModalComponent
} from 'src/app/shared/components/modal/modal.component';
import { VersoesTestesService } from 'src/app/shared/api/testes/versoes-testes.service';
import { versoesTesteResource } from '../versoes-teste.resource';
import { ActionsTabela, TabelaListagemComponent } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { ModulosComponent } from '../../modulos/modulos.component';
import { ColumnDataType, Resource } from 'src/app/shared/model/resource';
import { ActivatedRoute } from '@angular/router';
import { clonarObjeto } from 'src/app/shared/functions/objects-functions';

@Component({
    selector: 'app-teste-form',
    templateUrl: './teste-form.component.html',
    styleUrls: ['./teste-form.component.css']
})
export class TesteFormComponent implements OnInit {
    @ViewChild('formTeste') formTeste!: EditComponentComponent;
    @ViewChild('modalVersoesTeste') modalVersoesTeste!: ModalComponent;
    @ViewChild('tabelaVersoesTestes') tabelaVersoesTestes!: TabelaListagemComponent;
    @ViewChild('modulosComponent') modulosComponent!: ModulosComponent;

    testeResourceConfiguration!: Resource;
    entidadeOriginal: any;
    id: any;
    actions: ActionToolbar[] = [];
    dataModalVersoesTeste: IDialogData = {
        titulo: 'Versões do teste',
        labelCancel: 'Fechar'
    };

    colunasVersoesTestes = versoesTesteResource;
    actionsVersoesTestes: ActionsTabela[] = [];

    constructor(
        public testeService: TestesService,
        public versoesTesteService: VersoesTestesService,
        private dialog: DialogService,
        private notify: NotificationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');

        this.actionsVersoesTestes.push({
            text: 'Reativar versão',
            hint: 'Reativar versão',
            icon: 'flash_on',
            type: 'primary',
            onClick: this.reativarVersao
        });

        this.configurarColunasFormulario();
    }

    configurarColunasFormulario(){
        if(!this.id)
            this.testeResourceConfiguration = testeResource;

        else {
            const resource = clonarObjeto(testeResource);
            resource.linhas!.unshift({
                colunas: [
                    {
                        field: 'capa',
                        label: 'Capa',
                        required: false,
                        type: ColumnDataType.Midia,
                        cardMidiaConfiguration: {
                            content_type: "teste",
                            obj_referencia: this.id,
                        },
                        sizeColumn: 'col-12',
                        sizeCellTable: 30
                    }
                ],
                sizeColumn: 'col-md-6'
            })
            this.testeResourceConfiguration = resource;
        }
    }

    changeEntidadeOriginal(entidade: any) {
        this.actions = [];
        if (!entidade.ativo) {
            this.actions.push({
                icon: 'flash_on',
                text: 'Ativar',
                type: 'accent',
                onClick: this.ativarTeste()
            });
        }
        if (this.entidadeOriginal) {
            this.actions.push({
                icon: 'manage_history',
                type: 'basic',
                text: 'Gerar nova versão',
                onClick: this.gerarNovaVersaoTeste()
            });
            this.actions.push({
                icon: 'timeline',
                type: 'basic',
                text: 'Visualizar versões do teste',
                onClick: this.visualizarVersoesTeste()
            });

            this.versoesTesteService.setBaseUrl(this.entidadeOriginal.uuid);
        }
    }

    ativarTeste = () => {
        return new Observable((observer) => {
            this.dialog
                .confirm(
                    'Confirmar ativação',
                    'Deseja realmente ativar esse teste?'
                )
                .subscribe({
                    next: (response: boolean) => {
                        if (response) {
                            this.testeService
                                .ativarTeste(this.entidadeOriginal.uuid)
                                .subscribe({
                                    next: () => {
                                        this.notify.success(
                                            'Teste ativo com sucesso!'
                                        );
                                        this.formTeste.reload();
                                        observer.next();
                                        observer.complete();
                                    },
                                    error: (error: any) => {
                                        this.notify.error(error.error.erro);
                                        observer.error();
                                        observer.complete();
                                    }
                                });
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    }
                });
        });
    };

    gerarNovaVersaoTeste = () => {
        return new Observable((observer) => {
            this.dialog
                .confirm(
                    'Confirmar nova versão',
                    'Deseja realmente gerar uma nova versão desse teste?'
                )
                .subscribe({
                    next: (response: boolean) => {
                        if (response) {
                            this.versoesTesteService
                                .gerarNovaVersaoTeste(
                                    this.entidadeOriginal.uuid
                                )
                                .subscribe({
                                    next: (response) => {
                                        this.modulosComponent.listarModulos();
                                        observer.next();
                                        observer.complete();
                                    },
                                    error: (err: any) => {
                                        observer.next();
                                        observer.complete();
                                    }
                                });
                        } else {
                            observer.next();
                            observer.complete();
                        }
                    }
                });
        });
    };

    visualizarVersoesTeste = () => {
        return new Observable((observer) => {
            this.showModalVisualizarVersoesTeste();
            observer.next();
            observer.complete();
        });
    };

    reativarVersao = (data: any) => {
        const idVersao = data.uuid;

        this.dialog
            .confirm(
                'Reativar versão',
                'Deseja realmente reativar a versão do teste selecionado?'
            )
            .subscribe({
                next: (response: boolean) => {
                    if (response) {
                        this.versoesTesteService
                            .restaurarVersao(idVersao)
                            .subscribe({
                                next: (response) => {
                                    this.formTeste.reload();
                                    this.closeModalVisualizarVersoesTeste();
                                }
                            });
                    }
                }
            });
    };

    showModalVisualizarVersoesTeste = () => {
        this.modalVersoesTeste.open();
        this.tabelaVersoesTestes.listarEntidades();
    };

    closeModalVisualizarVersoesTeste = () => {
        this.modalVersoesTeste.close(true);
    };
}
