<div class="midia small-shadow border rounded">
    <mat-card class="example-card">
        <ng-container *ngIf="file">
            <img
                *ngIf="['png', 'jpg', 'jpeg'].includes(file.formato!.toLocaleLowerCase())"
                [src]="file.url"
                mat-card-image
                class="content-card"
            />
            <video
                class="content-card"
                controls
                *ngIf="
                    [
                        'mp4',
                        'mov',
                        'mpeg-1',
                        'mpeg-2',
                        'mpeg-4',
                        'mpg',
                        'avi',
                        'wmv'
                    ].includes(file.formato!.toLocaleLowerCase())
                "
            >
                <source [src]="file.url" />
            </video>
        </ng-container>
        <mat-card-content *ngIf="!file" class="content-card">
            <div class="content-text">Selecionar arquivo</div>
            <input
                (change)="changeFile($event)"
                type="file"
                class="input-file"
            />
        </mat-card-content>
        <mat-card-actions>
            <div class="d-flex justify-content-between">
                <button
                    *ngIf="file"
                    (click)="excluirMidia()"
                    mat-button
                    color="warn"
                >
                    <mat-icon>delete</mat-icon>
                    Excluir arquivo
                </button>

                <div
                    *ngIf="file?.posicao"
                    class="d-flex justify-content-center align-items-center"
                >
                    {{ file.posicao }}º
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
