import { TemplateApiService } from '../api/template-api.service';
import { ActionsTabela } from '../components/tabela-listagem/tabela-listagem.component';

export enum ColumnDataType {
    Text = 'TextBox',
    Password = 'Password',
    Checkbox = 'CheckBox',
    Select = 'Selectbox',
    Color = 'Color',
    Date = 'DateBox',
    Number = 'NumberBox',
    Lookup = 'lookup',
    CargaHoraria = 'cargaHoraria',
    TextArea = 'TextArea',
    Midia = 'Midia'

    // 'dxAutocomplete' | 'dxCalendar' | 'dxColorBox' | 'dxDropDownBox' | 'dxHtmlEditor'
    // 'dxLookup' | 'dxRadioGroup' | 'dxRangeSlider' | 'dxSlider' | 'dxSwitch' | 'dxTagBox'
}

export interface ColumnValidation {
    type: 'email' | 'compare' | 'pattern' | 'range' | 'numeric';
    message?: string;
}

export interface LookupConfiguration {
    colunas: ConfigurationColumn[];
    /**
     * Usar nome da classe, exemplo UsuarioService
     */
    service?: any;
    /**
     * Usar a instancia da classe, exemplo: usuarioService = new UsuarioService();
     */
    instanciaService?: any;
    fieldPk: string;
    fieldName: string;
}

export interface SelectConfiguration {
    displayExpr: string;
    valueExpr: string;
    readOnly?: boolean;
    defaultData?: any[];
    route?: string;
    service?: any;
    onChange?: Function;
    calculateDisplayValue?: (entity: any) => any;
}

export interface NumberConfiguration {
    maxValue?: number;
    minValue?: number;
}

export interface TextareaConfiguration {
    rows: number;
}

export interface CardMidiaConfiguration {
    obj_referencia: string;
    content_type: string;
}

export interface ConfigurationColumn {
    field: string;
    required?: boolean;
    label?: string;
    visible?: boolean;
    type?: ColumnDataType;
    validation?: ColumnValidation[];
    defaultValue?: any;
    mask?: string;
    readOnly?: boolean;
    /* Usar os valores do bootstrap, como col-lg-6, col-md-10, assim por diante */
    sizeColumn?: string;
    /**
     * Tamanho da celula na tabela
     * Usar valores de 0 a 100
     */
    sizeCellTable?: number;

    lookupConfiguration?: LookupConfiguration;
    selectConfiguration?: SelectConfiguration;
    numberConfiguration?: NumberConfiguration;
    textareaConfiguration?: TextareaConfiguration;
    cardMidiaConfiguration?: CardMidiaConfiguration;

    getValueBeforEntity?: (value: any) => any;
    getValueBeforSave?: (value: any) => any;
    visibleForm?: (entity: any) => boolean;
    calculateDisplayValue?: (entity: any) => any;
    calculateDisplayTooltip?: (entity: any) => any;
    unAvailableOnList?: boolean;
    unAvailableOnEdit?: boolean;
    unAvailableOnCreate?: boolean;
}

export interface ConfigurationRows {
    colunas: ConfigurationColumn[],
    /* Usar os valores do bootstrap, como col-lg-6, col-md-10, assim por diante */
    sizeColumn?: string,
}

export interface Resource {
    nomeEntidade: {
        singular: string;
        plural: string;
    };
    /**
     *Rota da api que fara as operações de consulta, etc
     */
    route: {
        url: string;
        targetUrl?: string;
    };
    /**
     * Nome do campo que referencia o id (pk) da entidade.
     */
    fieldPk: string;
    fieldName?: string,
    linhas?: ConfigurationRows[],
    colunas: ConfigurationColumn[];
    availableReferences: AvailableReference[];

    disableNew?: boolean;
    disableBefore?: boolean;
    disableDelete?: boolean;
    disableEdit?: boolean;

    actions: ActionsTabela[];
}

export interface AvailableReference extends Resource {
    tipoAssociacao: 'OneToMany' | 'ManyToOne' | 'ManyToMany';
    isVisible?: (entity: any) => boolean;
}
