<form [formGroup]="parentFormGroup">
    <mat-form-field appearance="fill" (click)="openModal()">
        <mat-label>{{ coluna.label || coluna.field }}</mat-label>
        <input
            class="input-lookup"
            [formControlName]="coluna.field"
            matInput
            [(ngModel)]="textoInput"
            [name]="coluna.field"
            [readonly]="true"
        />

        <div matSuffix class="container-btn-edit-input">
            <button mat-mini-fab color="basic" class="btn-edit-input">
                <mat-icon>mode_edit</mat-icon>
            </button>
        </div>
    </mat-form-field>

    <modal #modal [data]="modalData" [onSave]="saveLookupComponent()">
        <tabela-listagem
            #tabela
            [colunas]="coluna.lookupConfiguration!.colunas"
            [service]="service"
            [ativarSelecao]="true"
            [selectionMode]="'single'"
            [valueExpr]="coluna.lookupConfiguration!.fieldPk"
            (finalizouListagem)="finalizouListagemTabela()"
        ></tabela-listagem>
    </modal>
</form>
