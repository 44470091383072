import { Component, OnInit } from '@angular/core';
import { pessoalLicencaResource } from './pessoal-licencas.resource';
import { LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { ActionsTabela } from 'src/app/shared/components/tabela-listagem/tabela-listagem.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pessoal-licencas',
    templateUrl: './pessoal-licencas.component.html',
    styleUrls: ['./pessoal-licencas.component.css']
})
export class PessoalLicencasComponent implements OnInit {

    pessoalLicencaResource = pessoalLicencaResource;
    actions: ActionsTabela[] = [];

    constructor(
        public licencasService: LicencasService,
        private jogadaService: JogadaService,
        private dialog: DialogService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.actions.push({
            hint: 'Iniciar teste',
            icon: 'play_arrow',
            text: '',
            type: 'primary',
            mode: 'single',
            onClick: this.iniciarTeste,
            isVisible: (entity: any) => entity.data_fim == null
        })

        this.actions.push({
            hint: 'Visualizar Relatório',
            icon: 'assignment',
            text: '',
            type: 'primary',
            mode: 'single',
            onClick: this.visualizarRelatorio,
            isVisible: (entity: any) => entity.data_fim != null
        })
    }

    iniciarTeste = (licenca: any) => {
        this.dialog.confirm('Confirmar', 'Deseja realmente iniciar o jogo agora?')
            .subscribe(confirmado => {
                if(confirmado){
                    this.jogadaService.getRodadaAtual(licenca.uuid).subscribe({
                        next: response => {
                            this.goToJogada(licenca.uuid);
                        },
                        error: () => {
                            this.jogadaService.iniciarJogada(licenca.uuid).subscribe({
                                next: response => {
                                    this.goToJogada(licenca.uuid);
                                }
                            });
                        }
                    });
                }
            });
    }

    goToJogada(id: string){
        this.router.navigate([`/play/${id}`]);
    }

    visualizarRelatorio = (licenca: any) => {
        const url = 'relatorios/' + licenca.uuid;
        this.router.navigate([url]);
    }
}
