import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'primary-button',
    templateUrl: './primary-button.component.html',
    styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent implements OnInit {

    @Input() icon: string | null = null;
    @Input() text: string | null = null;
    @Input() routerLink: string | null = null;

    constructor() { }

    ngOnInit(): void {
    }

}
