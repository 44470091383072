import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelatoriosRoutingModule } from './relatorios-routing.module';
import { RelatoriosComponent } from './relatorios.component';
import { ResultadoComponent } from './resultado/resultado.component';
import { RelatorioService } from 'src/app/shared/api/testes/relatorios.service';
import { SafeHtmlRelatorioPipe } from './safeHtmlPipeRelatorio';

@NgModule({
    declarations: [RelatoriosComponent, ResultadoComponent, SafeHtmlRelatorioPipe],
    imports: [CommonModule, RelatoriosRoutingModule],
    providers: [RelatorioService, SafeHtmlRelatorioPipe]
})
export class RelatoriosModule {}
