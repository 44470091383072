import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

    @Input() icon: string | null = null;
    @Input() text: string | null = null;
    @Input() color: 'primary' | 'accent' | 'warning' | null = null;
    @Input() routerLink!: string | null;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    buttonClick(){
        if(this.routerLink){
            this.router.navigate([this.routerLink]);
        }
    }
}
