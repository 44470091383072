import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';

@NgModule({
    declarations: [ButtonComponent, PrimaryButtonComponent, SecondaryButtonComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule],
    exports: [PrimaryButtonComponent, ButtonComponent, SecondaryButtonComponent]
})
export class ButtonModule {}
