<container-padrao>
    <card>
        <div class="mb-2 d-flex justify-content-between">
            <h2>{{pessoalLicencaResource.nomeEntidade.plural}}</h2>
        </div>

        <tabela-listagem
            [colunas]="pessoalLicencaResource.colunas"
            [service]="licencasService"
            [actions]="actions"
        ></tabela-listagem>
    </card>
</container-padrao>