import { truncateText } from 'src/app/shared/functions/format-functions';
import { ColumnDataType, Resource } from '../../shared/model/resource';
import { displayColorHexadeciamlToHTMLCircle } from 'src/app/shared/functions/color-functions';

export const testeResource: Resource = {
    disableDelete: true,
    nomeEntidade: {
        singular: 'Curso',
        plural: 'Cursos'
    },
    fieldPk: 'uuid',
    fieldName: 'nome',
    route: {
        url: 'testes'
    },
    linhas: [
        {
            sizeColumn: 'col-md-6',
            colunas: [
                {
                    field: 'nome',
                    required: true,
                    sizeColumn: 'col-md-12 col-lg-12',
                    sizeCellTable: 30
                },
                {
                    field: 'descricao',
                    label: 'Descrição',
                    type: ColumnDataType.TextArea,
                    textareaConfiguration: {
                        rows: 10
                    },
                    sizeColumn: 'col-md-12 col-lg-12',
                    sizeCellTable: 40,
                    calculateDisplayValue: (text: string) => truncateText(text, 200)
                },
                {
                    field: 'ativo',
                    label: 'Ativo',
                    unAvailableOnCreate: true,
                    unAvailableOnEdit: true,
                    calculateDisplayValue: (ativo: boolean) => (ativo ? 'Sim' : 'Não')
                }
            ]
        }
    ],
    colunas: [
        {
            field: 'nome',
            required: true,
            sizeColumn: 'col-md-12 col-lg-12',
            sizeCellTable: 30
        },
        {
            field: 'descricao',
            label: 'Descrição',
            type: ColumnDataType.TextArea,
            textareaConfiguration: {
                rows: 10
            },
            sizeColumn: 'col-md-12 col-lg-12',
            sizeCellTable: 40,
            calculateDisplayValue: (text: string) => truncateText(text, 200)
        },
        {
            field: 'ativo',
            label: 'Ativo',
            unAvailableOnCreate: true,
            unAvailableOnEdit: true,
            calculateDisplayValue: (ativo: boolean) => {
                if (ativo) return displayColorHexadeciamlToHTMLCircle('#28a745', 15, 15);
                return displayColorHexadeciamlToHTMLCircle('#dc3545', 15, 15);
            },
            calculateDisplayTooltip: (value) => (value ? 'Ativo' : 'Inativo')
        }
    ],
    actions: [],
    availableReferences: []
};
