import { dateToString, unformatedStringDateHourToPortugueseDateHourString } from "src/app/shared/functions/data-transform";
import { ColumnDataType, Resource } from "../../shared/model/resource";

export const pessoalLicencaResource: Resource = {
    nomeEntidade: {
        singular: 'Meu Curso',
        plural: 'Meus Cursos'
    },
    fieldPk: 'uuid',
    route: {
        url: 'testes',
    },
    colunas: [
        {
            field: 'teste.nome',
            label: 'Teste'
        },
        {
            field: 'equipe.nome',
            label: 'Equipe'
        },
        {
            field: 'data_inicio',
            label: 'Início',
            type: ColumnDataType.Date,
            calculateDisplayValue: (data: string) => {
                if(data == null) return '<span class="badge rounded-pill bg-success ps-2 pe-2 pt-2" style="font-weight: normal"">Não iniciado</span>';
                return unformatedStringDateHourToPortugueseDateHourString(data);
            },
            calculateDisplayTooltip: (data: string) => unformatedStringDateHourToPortugueseDateHourString(data)
        },
        {
            field: 'disponivel_ate',
            label: 'Disponível até',
            type: ColumnDataType.Date,
            calculateDisplayValue: (data: string) => {
                if(!data) return '';
                const dataDisponivel = new Date(data);
                const dataAtual = new Date();

                if(dataDisponivel < dataAtual){
                    return `<span class="badge rounded-pill bg-danger ps-2 pe-2 pt-2" style="font-weight: normal"">${dateToString(dataDisponivel)}</span>`;
                }
                return `<span class="badge rounded-pill bg-success ps-2 pe-2 pt-2" style="font-weight: normal"">${dateToString(dataDisponivel)}</span>`;
            },
            calculateDisplayTooltip: (data: string) => unformatedStringDateHourToPortugueseDateHourString(data)
        },
        {
            field: 'data_fim',
            label: 'Concluido em',
            type: ColumnDataType.Date,
            calculateDisplayValue: (data: string) => {
                if(!data) return '';
                return unformatedStringDateHourToPortugueseDateHourString(data);
            },
            calculateDisplayTooltip: (data: string) => unformatedStringDateHourToPortugueseDateHourString(data)
        },
    ],
    actions: [],
    availableReferences: []
}
