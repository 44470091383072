import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { environment } from 'src/environments/environment';
import { DialogService } from '../../services/dialog.service';
import { AdmMidiasService, IMidia } from '../../api/testes/adm-midias.service';
import { IMAGE_FORMATS, VIDEO_FORMATS } from './image-video-formats.resource';


@Component({
    selector: 'card-midia',
    templateUrl: './card-midia.component.html',
    styleUrls: ['./card-midia.component.css']
})
export class CardMidiaComponent implements OnInit {

    @Input() file!: IMidia;
    @Input() content_type!: string;
    @Input() obj_referencia!: string;

    @Output() changeMidia = new EventEmitter<any>();

    baseUrl = environment.urlMidias;
    imageFormats = IMAGE_FORMATS;
    videoFormats = VIDEO_FORMATS;

    constructor(
        private midiaService: AdmMidiasService,
        private dialog: DialogService
    ) { }

    ngOnInit(): void {
    }

    changeFile(evt: any) {
        const file = evt.target.files[0];
        if (file) {
            this.salvarMidia(file);
            evt.target.value = '';
        }
    }

    salvarMidia(file: any) {
        const formData = new FormData();
        formData.append('midia', file);
        formData.append('obj_referencia', this.obj_referencia);
        formData.append('content_type', this.content_type);

        this.midiaService.create(formData).subscribe({
            next: () => {
                this.changeMidia.emit();
            },
            error: error => {
                console.log(error)
            },
        });
    }

    excluirMidia = () => {
        this.dialog.confirm('Confirmar Exclusão', 'Tem certeza que deseja excluir a mídia atual?')
        .subscribe({
            next: (result: boolean) => {
                if(result){
                    this.midiaService.delete(this.file.uuid, { obj_referencia: this.obj_referencia, content_type: this.content_type })
                    .subscribe(response => {
                        this.changeMidia.emit();
                    })
                }
            }
        })
    }
}
