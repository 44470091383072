import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RelatorioService } from 'src/app/shared/api/testes/relatorios.service';

@Component({
    selector: 'app-resultado',
    templateUrl: './resultado.component.html',
    styleUrls: ['./resultado.component.css']
})
export class ResultadoComponent implements OnInit {
    id: any;
    htmlRelatorio: any = '';

    constructor(
        private relatorioService: RelatorioService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.obterRelatorio();
    }

    obterRelatorio() {
        this.relatorioService.getRelatorio(this.id).subscribe({
            next: (response) => {
                this.htmlRelatorio = response;
            },
        })
    }
}
