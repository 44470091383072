import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormularioDinamicoComponent } from './formulario-dinamico.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ModalModule } from '../modal/modal.module';
import { TabelaListagemModule } from "../tabela-listagem/tabela-listagem.module";
import { LookupModule } from "../lookup/lookup.module";
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { CardMidiaModule } from "../card-midia/card-midia.module";
import { CardMidiaFormularioModule } from "../card-midia-formulario/card-midia-formulario.module";



@NgModule({
    declarations: [
        FormularioDinamicoComponent,
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    exports: [FormularioDinamicoComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        ModalModule,
        TabelaListagemModule,
        LookupModule,
        ColorPickerModule,
        CardMidiaModule,
        CardMidiaFormularioModule
    ]
})
export class FormularioDinamicoModule { }
