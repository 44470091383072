<edit-component
    *ngIf="testeResourceConfiguration"
    #formTeste
    rota="testes"
    [service]="testeService"
    [resource]="testeResourceConfiguration"
    [(entidadeOriginal)]="entidadeOriginal"
    (entidadeOriginalChange)="changeEntidadeOriginal($event)"
    [actionsIncrement]="actions"
></edit-component>

<ng-container *ngIf="entidadeOriginal">
    <container-padrao>
        <modulos-testes #modulosComponent></modulos-testes>
    </container-padrao>
</ng-container>

<modal
    #modalVersoesTeste
    [data]="dataModalVersoesTeste"
    [showBtnConfirmar]="false"
>
    <tabela-listagem
        #tabelaVersoesTestes
        [service]="versoesTesteService"
        [colunas]="colunasVersoesTestes.colunas"
        [actions]="actionsVersoesTestes"
    >
    </tabela-listagem>
</modal>
