import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PessoalLicencasRoutingModule } from './pessoal-licencas-routing.module';
import { PessoalLicencasComponent } from './pessoal-licencas.component';
import { TabelaListagemModule } from '../../shared/components/tabela-listagem/tabela-listagem.module';
import { LicencasService } from 'src/app/shared/api/licencas/licencas.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { JogadaService } from 'src/app/shared/api/testes/jogada.service';
import { ContainerPadraoModule } from '../../shared/components/container-padrao/container-padrao.module';
import { CardModule } from '../../shared/components/card/card.module';

@NgModule({
    declarations: [PessoalLicencasComponent],
    imports: [
        CommonModule,
        PessoalLicencasRoutingModule,
        TabelaListagemModule,
        ContainerPadraoModule,
        CardModule,
    ],
    providers: [LicencasService, DialogService, JogadaService]
})
export class PessoalLicencasModule {}
