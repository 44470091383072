<ng-template>
    <h2 matDialogTitle>{{ data.titulo }}</h2>

    <mat-dialog-content>
        <ng-content></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-content-end">
        <button mat-button (click)="cancelar()" class="me-2" *ngIf="showBtnCancelar">
            {{data.labelCancel || 'Cancelar'}}
        </button>

        <primary-button (click)="concluir()" cdkFocusInitial *ngIf="showBtnConfirmar">
            {{data.labelOk || 'Confirmar'}}
        </primary-button>
    </mat-dialog-actions>
</ng-template>
