import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectConfiguration } from '../../model/resource';
import { ApiService } from '../../services/api.service';
import { PaginatedResponse } from '../../api/pagination.model';


@Component({
    selector: 'select-padrao',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

    @Input() value: any; // objeto
    @Input() formControlName: any;
    @Input() selectConfiguration!: SelectConfiguration;

    @Output() valueChange = new EventEmitter();

    optionsSelect: any[] = [];

    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        this.listarSelect();
    }

    listarSelect(){
        if(this.selectConfiguration.defaultData){
            this.optionsSelect = this.selectConfiguration.defaultData;
        } else{
            let request
            if(this.selectConfiguration.route){
                request = this.apiService.get(this.selectConfiguration.route);
            } else if(this.selectConfiguration.service){
                request = this.selectConfiguration.service.get();
            }

            request.subscribe({
                next: (response: PaginatedResponse<any>) => {
                    this.optionsSelect = response.resultados;
                }
            })
        }
    }

    onSelectChangeValue($event: any){
        const valor = this.optionsSelect.find(option => option[this.selectConfiguration.valueExpr] === $event);
        this.valueChange.emit(valor);
        this.selectConfiguration.onChange ? this.selectConfiguration.onChange(valor) : null;
    }

    calculateDisplayValue(value: any){
        if(this.selectConfiguration.calculateDisplayValue){
            return this.selectConfiguration.calculateDisplayValue(value);
        }
        return value[this.selectConfiguration.displayExpr];
    }
}
