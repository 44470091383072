import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IMidia } from 'src/app/shared/api/testes/adm-midias.service';
import {
    IAlternativaJogada,
    IJogada,
    IPerguntaJogada,
    JogadaService,
    ProximoEnum
} from 'src/app/shared/api/testes/jogada.service';
import { MidiasService } from 'src/app/shared/api/testes/midias.service';
import { IModulo } from 'src/app/shared/api/testes/modulos.service';
import { IRodada } from 'src/app/shared/api/testes/rodada.service';

@Component({
    selector: 'app-play',
    templateUrl: './play.component.html',
    styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit, OnDestroy {
    public idTeste!: string;
    public idLicenca!: string;
    private idJogada!: string;
    private idPergunta!: string;
    private idModulo!: string;
    private idRodada!: string;

    public modulo!: IModulo;
    public rodada!: IRodada;
    public pergunta!: IPerguntaJogada;
    public alternativas: IAlternativaJogada[] = [];
    public progresso = 0;
    public feedbackAtual: string | null = null;
    private proximaJogada: IJogada | null = null;

    public midias_modulo: IMidia[] = [];
    public midias_rodada: IMidia[] = [];
    public midias_alternativas: IMidia[] = [];

    public capaVisible = true;
    public moduloVisible = false;
    public rodadaVisible = false;
    public perguntasVisible = false;
    public finalizadoVisible = false;
    public feedbackVisible = false;

    public showLoaderPerguntas = true;
    public showLoaderModuloRodada = true;

    public corPrimariaJogo = '#06d6a0';
    public corSecundariaJogo = '#10001a';

    private _subscriberGetRodadaAtual: Subscription | null = null;
    private _subscriberGetModiasModulo: Subscription | null = null;
    private _subscriberGetModiasRodada: Subscription | null = null;
    private _subscriberGetModiasPerguntas: Subscription | null = null;

    constructor(
        private route: ActivatedRoute,
        private jogadaService: JogadaService,
        private midiaService: MidiasService
    ) {}

    ngOnInit(): void {
        this.idLicenca = this.route.snapshot.paramMap.get('id') as string;
        this.getInformacoesJogada(this.idLicenca);
    }

    ngOnDestroy(): void {
        this._subscriberGetRodadaAtual?.unsubscribe();
        this._subscriberGetModiasModulo?.unsubscribe();
        this._subscriberGetModiasRodada?.unsubscribe();
        this._subscriberGetModiasPerguntas?.unsubscribe();
    }

    irParaModulo(){
        this.showModulo();
    }

    irParaRodada() {
        this.showRodada();
    }

    irParaPerguntas() {
        this.iniciarPergunta();
    }

    voltarParaRodada() {
        this.showRodada();
    }

    responderPergunta = (alternativa: IAlternativaJogada) => {
        this.enviarRespostaJogador(alternativa);
    };

    continuarProximaPergunta = () => {
        this.setarInformacoesJogada(this.proximaJogada!);
        this.redirecionarProximaPerguntaOuRodadaOuModulo(this.proximaJogada!);
    };

    getInformacoesJogada(id: string) {
        this._subscriberGetRodadaAtual = this.jogadaService.getRodadaAtual(id).subscribe({
            next: (response) => {
                this.setarCoresJogada(response);
                this.setarInformacoesJogada(response);

                if (response.acompanhamento.proximo == ProximoEnum.Finalizado) {
                    this.showFinalizado();
                } else {
                    this.getMidiaModulos(response.acompanhamento.jogada_atual.modulo);
                    this.getMidiaRodadas(response.acompanhamento.jogada_atual.rodada);
                }
            }
        });
    }

    private setarCoresJogada(jogada: IJogada){
        let corPrimariaJogo = jogada.skin?.cor_primaria || '#06d6a0';
        let corSecundariaJogo = jogada.skin?.cor_secundaria || '#10001a';
        document.documentElement.style.setProperty('--cor-primaria-jogo', corPrimariaJogo);
        document.documentElement.style.setProperty(
            '--cor-secundaria-jogo',
            corSecundariaJogo
        );
    }

    private setarInformacoesJogada(jogada: IJogada) {
        this.idJogada = jogada.uuid;
        this.idPergunta = jogada.acompanhamento.jogada_atual.pergunta;
        this.idModulo = jogada.acompanhamento.jogada_atual.modulo;
        this.idRodada = jogada.acompanhamento.jogada_atual.rodada;
        this.idTeste = jogada.acompanhamento.teste;

        this.modulo = jogada.acompanhamento.detalhes.modulo as IModulo;
        this.rodada = jogada.acompanhamento.detalhes.rodada as IRodada;
        if (jogada.acompanhamento.detalhes.pergunta)
            this.pergunta = jogada.acompanhamento.detalhes!.pergunta;
        this.alternativas = jogada.acompanhamento.detalhes.alternativas;
        this.progresso = jogada.acompanhamento.progresso;
        this.feedbackAtual = jogada.acompanhamento.detalhes.feedback || null;
    }

    private enviarRespostaJogador(alternativa: IAlternativaJogada) {
        this.jogadaService.responderPergunta(this.idJogada, alternativa.uuid).subscribe({
            next: (response) => {
                this.proximaJogada = response;
                this.exibirFeedback(response);
            }
        });
    }

    private exibirFeedback(jogada: IJogada) {
        this.feedbackAtual = jogada.acompanhamento.detalhes?.feedback || null;
        if (this.feedbackAtual) this.showFeedback();
        else this.continuarProximaPergunta();
    }

    private redirecionarProximaPerguntaOuRodadaOuModulo(jogada: IJogada) {
        switch (jogada.acompanhamento.proximo) {
            case ProximoEnum.Pergunta:
                this.iniciarPergunta();
                break;
            case ProximoEnum.Modulo:
                this.iniciarModulo();
                break;
            case ProximoEnum.Rodada:
                this.iniciarRodada();
                break;
            case ProximoEnum.Finalizado:
                this.showFinalizado();
                break;
        }
    }

    iniciarPergunta() {
        this.showLoaderPerguntas = true;
        this.getMidiaPerguntas(this.idPergunta).then(() => {
            this.jogadaService.iniciarPerguntas(this.idJogada, this.idPergunta).subscribe({
                next: (response) => {
                    this.setarInformacoesJogada(response);
                    this.showPerguntas();
                    this.showLoaderPerguntas = false;
                },
                error: () => {
                    this.showLoaderPerguntas = false;
                    this.showPerguntas();
                }
            });
        });
    }

    iniciarModulo() {
        this.getMidiaModulos(this.idModulo);
        this.showModulo();
    }

    iniciarRodada() {
        this.getMidiaRodadas(this.idRodada);
        this.showRodada();
    }

    getMidiaModulos(idModulo: string) {
        this.showLoaderModuloRodada = true;
        this._subscriberGetModiasModulo = this.midiaService.getMidiasModulos(idModulo).subscribe({
            next: (response) => {
                this.midias_modulo = response.resultados;
                this.showLoaderModuloRodada = false;
            }
        });
    }

    getMidiaRodadas(idRodada: string) {
        this.showLoaderModuloRodada = true;
        this._subscriberGetModiasRodada = this.midiaService.getMidiasRodada(idRodada).subscribe({
            next: (response) => {
                this.midias_rodada = response.resultados;
                this.showLoaderModuloRodada = false;
            }
        });
    }

    getMidiaPerguntas(idPergunta: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this._subscriberGetModiasPerguntas = this.midiaService
                .getMidiasPergunta(idPergunta)
                .subscribe({
                    next: (response) => {
                        this.midias_alternativas = response.resultados;
                        resolve();
                    },
                    error: (error) => {
                        reject();
                    }
                });
        });
    }

    private showCapa() {
        this.capaVisible = true;
        this.moduloVisible = false;
        this.rodadaVisible = false;
        this.perguntasVisible = false;
        this.finalizadoVisible = false;
        this.feedbackVisible = false;
    }

    private showModulo() {
        this.capaVisible = false;
        this.moduloVisible = true;
        this.rodadaVisible = false;
        this.perguntasVisible = false;
        this.finalizadoVisible = false;
        this.feedbackVisible = false;
    }

    private showRodada() {
        this.capaVisible = false;
        this.moduloVisible = false;
        this.rodadaVisible = true;
        this.perguntasVisible = false;
        this.finalizadoVisible = false;
        this.feedbackVisible = false;
    }

    private showPerguntas() {
        this.capaVisible = false;
        this.moduloVisible = false;
        this.rodadaVisible = false;
        this.perguntasVisible = true;
        this.finalizadoVisible = false;
        this.feedbackVisible = false;
    }

    private showFinalizado() {
        this.capaVisible = false;
        this.finalizadoVisible = true;
        this.moduloVisible = false;
        this.rodadaVisible = false;
        this.perguntasVisible = false;
        this.feedbackVisible = false;
    }

    private showFeedback() {
        this.capaVisible = false;
        this.finalizadoVisible = false;
        this.moduloVisible = false;
        this.rodadaVisible = false;
        this.perguntasVisible = false;
        this.feedbackVisible = true;
    }
}
