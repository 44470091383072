<div class="col-sm-6 esquerda p-0">
    <img src="assets/img/imagem_feedback.jpg" class="img-feedback">
</div>

<div class="col-sm-6 direita p-0">
    <div class="d-grid align-items-center">
        <div class="w-100">
            <h1 class="text-center mt-4 titulo">Feedback</h1>
        </div>

        <p class="p-4 mt-4 descricao">{{ texto }}</p>

        <btn-continuar
            (click)="onClickContinuar()"
            class="mt-4"
        ></btn-continuar>
    </div>
</div>
