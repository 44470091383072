import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { IRodada, RodadaService } from 'src/app/shared/api/testes/rodada.service';
import { IActionsCardbotoes } from 'src/app/shared/components/card-botoes/card-botoes.component';
import { NotificationService } from 'src/app/shared/services/notificationService';
import { rodadaResource } from './rodada.resource';
import { ConfigurationColumn } from 'src/app/shared/model/resource';
import { IDialogData, ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { FormularioDinamicoComponent } from 'src/app/shared/components/formulario-dinamico/formulario-dinamico.component';

@Component({
    selector: 'rodadas',
    templateUrl: './rodadas.component.html',
    styleUrls: ['./rodadas.component.css']
})
export class RodadasComponent implements OnInit, OnDestroy {
    @ViewChild('modal') modal!: ModalComponent;
    @ViewChild('formularioRodada') formularioRodada!: FormularioDinamicoComponent;

    @Input() idTeste!: string;
    @Input() idModulo!: string;

    readonly ADICIONAR_RODADA = 'Adicionar rodada';
    readonly EDITAR_RODADA = 'Editar rodada';

    rodadaResouce = rodadaResource;
    colunasFormularioRodada: ConfigurationColumn[] = [];
    dataModal: IDialogData = { titulo: '' };
    showFormModal = false;

    actions: IActionsCardbotoes[] = [];
    entidadeRodada!: any;
    listaRodadas: IRodada[] = [];


    private _subscriberListagemRodadas: Subscription | null = null;
    private _subscriberModal: Subscription | null = null;
    private _subscriberCreateRodada: Subscription | null = null;

    constructor(
        public rodadaService: RodadaService,
        private notify: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.rodadaService.setBaseUrl(this.idTeste, this.idModulo);

        this.actions.push({
            hint: this.EDITAR_RODADA,
            icon: 'edit',
            text: this.EDITAR_RODADA,
            type: 'primary',
            onClick: this.abrirModalEditarRodada
        })

        this.listarRodadas();
    }

    ngOnDestroy(): void {
        if (this._subscriberListagemRodadas) this._subscriberListagemRodadas.unsubscribe();
        if (this._subscriberModal) this._subscriberModal.unsubscribe();
        if (this._subscriberCreateRodada) this._subscriberCreateRodada.unsubscribe();
    }

    listarRodadas() {
        this._subscriberListagemRodadas = this.rodadaService.get({tamanho: 999}).subscribe(response => {
            this.listaRodadas = response.resultados;
        })
    }

    showModalFormularioRodada() {
        this.showFormModal = true;
        this._subscriberModal = this.modal.open({ width: '40em' }).afterClosed().subscribe({
            next: () => {
                this.showFormModal = false;
            }
        });
    }

    abrirModalCriarRodada() {
        this.entidadeRodada = {};
        this.dataModal.titulo = this.ADICIONAR_RODADA;
        this.colunasFormularioRodada = this.rodadaResouce.colunas.filter(c => !c.unAvailableOnCreate);
        this.showModalFormularioRodada();
    }

    abrirModalEditarRodada = (entity: IRodada) => {
        this.entidadeRodada = entity;
        this.dataModal.titulo = this.EDITAR_RODADA;
        this.colunasFormularioRodada = this.rodadaResouce.colunas.filter(c => c);
        this.showModalFormularioRodada();
    }

    onSaveRodada() {
        return new Observable(observer => {
            if (!this.formularioRodada.isValid()) {
                observer.error();
                return;
            }

            const rodada = this.formularioRodada.getDadosFormularioSalvar();

            let createOrSaveRequest = null;
            if (this.entidadeRodada?.uuid) {
                createOrSaveRequest = this.rodadaService.update(this.entidadeRodada.uuid, rodada);
            } else {
                createOrSaveRequest = this.rodadaService.create(rodada);
            }

            this._subscriberCreateRodada = createOrSaveRequest.subscribe({
                next: () => {
                    this.listarRodadas();
                    observer.next();
                },
                error: (e: any) => {
                    this.formularioRodada.tratarErroRequisicao(e);
                    this.notify.error(e.error.erro);
                    observer.error();
                }
            })
        })
    }
}
