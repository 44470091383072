<div class="col-sm-6 p-0">
    <slider-midia [midias]="midias" class="area-midias"></slider-midia>
</div>

<div class="col-sm-6 p-0 direita">
    <div *ngIf="!showLoaderPerguntas">
        <progresso [valor]="progresso"></progresso>

        <div class="d-flex justify-content-between">
            <button
                (click)="backFunction()"
                mat-mini-fab
                color="basic"
                class="me-2 ms-2 mt-2"
            >
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>

            <timer
                class="mt-2 me-2"
                [inicio]="pergunta.inicio"
                [duracao]="pergunta.duracao"
            ></timer>
        </div>

        <div class="p-2">
            <div class="mt-4 descricao">
                {{ pergunta.descricao }}
            </div>

            <alternativas-list
                [alternativas]="alternativas"
                [(value)]="alternativaSelecionada"
            ></alternativas-list>

            <div *ngIf="alternativaSelecionada" class="mt-3">
                <btn-continuar
                    [texto]="'Responder'"
                    (click)="confirmAlternativa()"
                ></btn-continuar>
            </div>
        </div>
    </div>

    <div *ngIf="showLoaderPerguntas">
        <div class="d-grid align-items-center justify-content-center">
            <loader-page [show]="true"></loader-page>
        </div>
    </div>
</div>
