import { IResponse } from "../response.model";
import { TemplateApiService } from "../template-api.service";
import { Injectable } from "@angular/core";


export interface ILicenca extends IResponse {
    acesso_pessoal: string,
    ativo: boolean,
    data_criacao: Date,
    data_inicio: Date,
    disponivel_ate: Date,
    disponivel_de: Date,
    dono: string,
    equipe: {uuid: string, nome: string},
    teste: {uuid: string, nome: string},
}


@Injectable()
export class LicencasService extends TemplateApiService<ILicenca> {

    public override setBaseUrl(params: {}) {
        this.baseUrl = `licencas`;
    }
}
