import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestesComponent } from './testes.component';
import { TableListModule } from "../../shared/components/table-list/table-list.module";
import { TestesRoutingModule } from './testes-routing.module';
import { TestesService } from 'src/app/shared/api/testes/testes.service';
import { TesteFormComponent } from './teste-form/teste-form.component';
import { EditComponentModule } from "../../shared/components/edit-component/edit-component.module";
import { CardModule } from "../../shared/components/card/card.module";
import { ModulosModule } from "../modulos/modulos.module";
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ModalModule } from "../../shared/components/modal/modal.module";
import { VersoesTestesService } from 'src/app/shared/api/testes/versoes-testes.service';
import { TabelaListagemModule } from "../../shared/components/tabela-listagem/tabela-listagem.module";
import { ToolbarModule } from "../../shared/components/toolbar/toolbar.module";
import { FormularioDinamicoModule } from "../../shared/components/formulario-dinamico/formulario-dinamico.module";
import { ContainerPadraoModule } from "../../shared/components/container-padrao/container-padrao.module";



@NgModule({
    declarations: [
        TestesComponent,
        TesteFormComponent
    ],
    providers: [
        TestesService,
        DialogService,
        VersoesTestesService
    ],
    imports: [
    CommonModule,
    TestesRoutingModule,
    TableListModule,
    EditComponentModule,
    CardModule,
    ModulosModule,
    ModalModule,
    TabelaListagemModule,
    ToolbarModule,
    ContainerPadraoModule
]
})
export class TestesModule { }
